import React, {useState, useEffect} from 'react'
import { Carousel } from 'antd';
import axios from 'axios';
import './Page.css';
import 'antd/dist/antd.css';
import logo from './../../image/logo.png'
import footerLogo from './../../image/dibu_logo.png'
import Home from '../home/Index';
import About from '../about/Index';
import Juzhen from '../juzhen/Index';
import JoinUs from '../JoinUs/Index';
import Case from '../Case/Index';
import Contact from '../Contact/Index';

const menuData = [
  {
    id: 0,
    name: '首页'
  },
  {
    id: 1,
    name: '关于珞石'
  },
  {
    id: 2,
    name: '矩阵群星'
  },
  {
    id: 3,
    name: '成功案例'
  },
  {
    id: 4,
    name: '加入我们'
  },
  {
    id: 5,
    name: '联系我们'
  },
]


function Page() {
  const [active, setActive] = useState(0)
  const [imgList, setImgList] = useState([])
  useEffect(()=>{
    console.log('active', active)
    axios.get('/admin/api/gw/queryContentList?type=0').then(res=>{
      console.log(res)
      const data = res.data
      const list = data.data
      console.log('list')
      console.log(list)
      setImgList(list)
    })
  },[])
  function onChange(a, b, c) {
    console.log(a, b, c);
  }

  function scrollTo(active) {
    if(active !== 0) {
      const height = document.querySelector('.header').offsetHeight || 0;
      document.querySelector('html, body').scrollTo({ top: height, behavior: 'smooth' })
    }
  }

  const contentStyle = {
    height: '160px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
  };

  const renderContent = ()=> {
    switch(active) {
      case 0:
        return <Home/>
      case 1:
        return <About/>
        case 2:
          return <Juzhen/>
      case 3:
        return <Case />
        case 4:
          return <JoinUs/>
      case 5:
        return <Contact />
      default:
        return <p>首页内容组件</p>

    }
  }

  return (
    <div className="App">
      <div className="header">
        <div className="menu">
          <img className="logo" src={logo} onClick={() => {setActive(0); scrollTo(0);}} />
          {menuData.map((item)=>{
            return <span key={item.id} onClick={()=> { setActive(item.id); scrollTo(item.id);}} className={active === item.id ? "item active" : "item"}>{item.name}</span>
          })}
        </div>
        <div className="swiper">
        <Carousel afterChange={onChange} autoplay={true}>
          {imgList.map((item)=>(
            <a href={item.link} target='blank' key={item.id} className="swiper-item">
              <img src={item.imgUrl} />
            </a>
          ))
          }
        </Carousel>
        </div>

      </div>
      <div className="content">
        {renderContent()}
      </div>
      <div className="footer">
        <div className="inner">
          <div className="footer_left">
            <div className="logo">
              <img src={footerLogo} />
            </div>
            <div className="desc">
            北京珞石文化传播有限公司是业内首批财经类MCN机构。
            专注于财经领域，珞石邀集并服务众多财经评论家、投资人和自媒体人，
            通过有效的推广运营和经纪服务，目前已经形成以微博为主，
            横跨今日头条、百家号、企鹅号等多家平台的综合性机构，
            致力成为有价值、有深度的内容创作和输出平台。
            </div>
          </div>
          <div className="footer_center">
              {menuData.slice(1).map((item)=>{
                return <div onClick={()=>{setActive(item.id); document.documentElement.scrollTop = 0;}} key={item.id} className="item">{item.name}</div>
              })}
          </div>
          <div className="footer_right">
            <div className="label">合作营销QQ: 84818088</div>
            <div className="label">Email: 84818088@qq.com</div>
            <div className="label">微博: @珞石文化</div>
            <div className="label">地址: 北京市海淀区北太平庄街道保利海德公园7号楼</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Page;

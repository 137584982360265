import React, {useRef, useEffect} from 'react';
import styles from './Index.module.scss';
const AMap = window.AMap;

const data = {
  company: '北京珞石文化网络传播有限公司',
  info: [
    {
      key: '珞石文化微博',
      value: '珞石文化'
    },
    // {
    //   key: '珞石合作电话',
    //   value: ''
    // },
    {
      key: '合作营销QQ',
      value: '84818088'
    },
    {
      key: 'Email',
      value: '84818088@qq.com'
    },
  ],
  address: '北京市海淀区北太平庄街道保利海德公园7号楼',
}

function Index() {
  useEffect(() => {
    // 创建地图
    const map = new AMap.Map('container', {
      zoom: 15,//级别
      center: [116.35192,39.974071],//中心点坐标
      viewMode:'3D'//使用3D视图
    });
    const marker = new AMap.Marker({
      icon: "https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png",
      title: "珞石文化",
      position: [116.35192,39.974071]
    });
    const scale = new AMap.Scale({
      visible: true
    });
    const toolBar = new AMap.ToolBar({
        visible: true
    });
    const overView = new AMap.OverView({
        visible: true
    });
    map.add(marker)
    map.addControl(scale);
    map.addControl(toolBar);
    map.addControl(overView);
  }, []);

  return (
    <div className={styles.cancat}>
      <h1>{data.company}</h1>
      <div>
        {data.info.map((item, index) => {
          return (
            <p className={styles.info} key={`concat_${index}`}><span>{item.key}：</span>{item.value}</p>
          )
        })}
        <p className={styles.info} style={{ marginTop: '88px' }}><span>地址：</span>{data.address}</p>
      </div>
      {/* 地图 */}
      <div id="container" className={styles.map} />
    </div>
  );
}

export default Index;
import React, {useState, useEffect} from 'react';
import axios from 'axios'
import './Index.css';

function Index() {
    const [specList, setSpecList] = useState([])
    useEffect(()=>{
        axios.get('/admin/api/gw/queryContentList?type=1').then(res=>{
          console.log(res)
          const data = res.data
          const list = data.data
          setSpecList(list)
        })
      },[])
    return <div className='home_This'>
        <div className='home_top'>
            <div className='home_Label'>我们的特点和优势</div>
            <div className='home_List'>
                {
                    specList.map((item, index) => {
                        return <img key={item.id} className='home_item' src={item.imgUrl}/>
                    })
                }
            </div>
        </div>
        <div className='home_top'>
            <div className='home_Label'>我们的合作伙伴</div>
            <div className='home_pinpai'></div>
        </div>
    </div>
}

export default Index;
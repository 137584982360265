import React, {useState, useEffect} from 'react'
import axios from 'axios'
import Intruction from '../Intruction/Index';
import './Index.css';

const data = {
    '如何加入珞石': {
        content: [{
            subTitle: '',
            content: {
            
                '联系我们': ['珞石文化汇集了大批财经领域优秀自媒体人，并为他们提供账号运营协助和商业变现服务。我们诚邀微博、头条号、百家号、抖音、快手、企鹅号等平台的优质财经内容创作者合作，欢迎与您沟通。可发邮件至84818088@qq.com，谢谢。'],
            }
            }],
        image: 'guanyu_logo3'},
    // '招聘信息': {
    //     content: [
    //     {
    //         subTitle: '业务助理1名',
    //         content: {
    //             '工作职责：': ['1、负责业务相关文件的分类、建档、归档工作；','2、收集、整理业务合同、统计资料及出具相关报表；','3、业务销售费用的统计、核算、管理、服务等；','4、为财务部门进行核算提供准确无误的数据支持；', '5、协助业务进行日常的财务报销。 '],
    //             '任职要求：': [' 1、大专以上学历，应届毕业生均可；', '2、具备亲和力和良好的沟通、组织协调能力；','3、具有良好的团队合作精神，有学习意识。' ],
    //         },
    //     },
    //     {
    //         subTitle: '策划副总监（1名）',
    //         content: {
    //             '工作职责：': [
    //                 '1、商务拓展，同时维护已有客户关系；',
    //                 '2、商务管理，制定商务合作计划；',
    //                 '3、团队管理，对客户服务团队进行管理；',
    //                 '4、帮助领导层共同制定公司战略发展规划；', 
    //                 '5、完成、提高公司销售业绩。 '
    //             ],
    //             '任职要求：': [
    //                 '1、深入了解新媒体，至少2年团队管理经验；', 
    //                 '2、有业务拓展、市场拓展丰富经验和相关资源'
    //             ],
    //         } 
    //     } 
    //     ],
    //     image: 'guanyu_logo4'
    // }
};

function Index() {
    const [jobList, setJobList] = useState([])
    useEffect(()=>{
        axios.get('/admin/api/gw/queryContentList?type=6').then(res=>{
          console.log(res)
          const data = res.data;
          const list = data.data || [];
          setJobList(list)
        })
      },[])
    return <div className='about_this'>
        <div className='about_top'>
            <Intruction data={data}/>
            <div className='intr_Label'>招聘信息</div>
            
                <div className='job-list'>
                    {jobList.map(item=>{
                        return (
                            <div key={item.id} className="job-item">
                                <div className="job-name">{item.name}</div>
                                <div dangerouslySetInnerHTML={{__html: `<div>${item.content}</div>`}} />
                            </div>
                        )
                    })}
                </div>
            
        </div>
    </div>
}

export default Index;
import React, {useState, useEffect} from 'react';
import axios from 'axios'
import { Carousel } from 'antd';
import styles from './Index.module.scss';

const jumpTo = (url) => {
  let a = document.createElement("a");
  a.setAttribute("href", url);
  a.setAttribute("target", "_blank");
  a.setAttribute("id", 'new_a');
  // 防止反复添加
  if(!document.getElementById('new_a')) {
      document.body.appendChild(a);
  }
  a.click();
  document.body.removeChild(a);
}

function Index() {
    const [caseList, setCaseList] = useState([])
    useEffect(()=>{
        axios.get('/admin/api/gw/queryContentList?type=5').then(res=>{
          console.log(res)
          const data = res.data;
          const list = data.data || [];
          setCaseList(list);
        })
      },[])
    return (
      <div className={styles.caseBanner}>
        <Carousel
          autoplay={true}
          dots={true}
          appendDots={
            dots => (
              <div>
                <ul style={{ margin: "0px", padding: "0px" }}> {dots} </ul>
              </div>
            )
          }
          dotsClass="slick-dots slick-thumb"
        >
          {
            caseList.map((item)=>(
              <div key={`case_${item.id}`} className={styles.item} onClick={() => jumpTo(item.link)}>
                <img src={item.imgUrl} />
              </div>
            ))
          }
        </Carousel>
      </div>
    );
}

export default Index;
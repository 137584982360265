import React, {useState, useEffect} from 'react'
import './Index.css';
import Intruction from '../Intruction/Index';
import guanggao from '../../image/gongguan_icon.png';
import cehua from '../../image/zhenghe_icon.png';
import yingxiao from '../../image/meiti_icon.png';

const data = {
    '关于珞石文化': {
        content: [{
            subTitle: '',
            content: {
                '业内首批财经类MCN机构': ['珞石文化是业内首批财经类MCN机构，珞石邀集并服务众多财经评论家、投资人和自媒体人，通过有效的推广运营和经纪服务，为财经自媒体人提供更好的孵化和成长环境，为他们的内容创作和传播提供优质平台，助力财经媒体人个人IP的建立与发展。'],
                '连续三年十大影响力MCN机构之一': ['在微博平台，珞石机构账号粉丝总数超6000万，月均内容创作量超3万条，均列微博财经MCN合作机构首位。2018/2019/2020珞石文化连续三年被评选为微博十大影响力MCN机构之一。'],
                '多平台多矩阵布局': ['珞石横跨微博、头条号、百家号、企鹅号、抖音、快手等各主要媒体平台建立MCN机构矩阵，为财经领域内容创作者提供全网运营协助。']
            }
            }],
        image: 'guanyu_logo1'
    },
    '我们的荣誉': {
        content: [{
            subTitle: '',
            content: {
                '2018年': ['· 新榜年度MCN奖','· 微博十大影响力财经机构'],
                '2019年': ['· 微博红人节优培合作伙伴','· 微博十大影响力财经机构'],
                '2020年': ['· 微博十大影响力财经机构']
            }
        }],
        image: 'guanyu_logo2',
    }
}

const data2 = [
    {
        key: '公关传播',
        value: '拥有微博、头条、百家号、企鹅号等',
        value1: '多平台财经自媒体矩阵，',
        value2: '为品牌方提供全网品牌传播及舆情管理服务。',
        image: guanggao
    },{
        key: '整合营销',
        value: '依托阵容强大的财经自媒体团队，',
        value1: '为各类金融机构C端业务',
        value2: '提供从品牌到业务执行的整合营销方案。',
        image: cehua
    },{
        key: '自媒体运营',
        value: '为优质内容创作者提供多平台运营支持',
        value1: '及账号孵化，建立IP全网影响力，',
        value2: '并提供电商、投教等全类别变现合作通道',
        image: yingxiao
    }]
function Index() {
    return <div className='about_this'>
        <div className='about_top'>
            <Intruction data={data}/>
        </div>
        <div className='about_bottomTitle'>我们的主营业务</div>
        <div className='about_bottom'>
            <div className='about_bottomBox'>
                {
                    data2.map((item,index)=>{
                        return <div className='about_bottomInfo' key={index}>
                            <img src={item.image}/>
                            <div className='about_bottomKey'>{item.key}</div>
                            <div className='about_bottomValue'>{item.value}</div>
                            <div className='about_bottomValue'>{item.value1}</div>
                            <div className='about_bottomValue'>{item.value2}</div>
                        </div>
                    })
                }
            </div>
        </div>
    </div>
}

export default Index;
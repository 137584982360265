import React, { useState, useEffect, useRef } from "react";
import { Carousel } from "antd";
import styles from './sourceView.module.scss';
import Dy from '../../../image/juzhen/douyin_icon.png';
import Ks from '../../../image/juzhen/kuaishou_icon.png';
import Wx from '../../../image/juzhen/weixin_icon.png';
import Wb from '../../../image/juzhen/weibo_icon.png';

const goHref = url => {
  let a = document.createElement("a");
  a.setAttribute("href", url);
  a.setAttribute("target", "_blank");
  a.setAttribute("id", 'new_a');
  // 防止反复添加
  if(!document.getElementById('new_a')) {
      document.body.appendChild(a);
  }
  a.click();
  document.body.removeChild(a);
}

// 左键头
function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      className={styles.prevArrow}
      onClick={onClick}
    />
  );
}

// 右键头
function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div
      className={styles.nextArrow}
      onClick={onClick}
    />
  );
}

// 博主平台
function Platform(props) {
  const { data } = props;

  return (
    <div className={styles.platform}>
      {!!data.linkWb && <img src={Wb} onClick={() => goHref(data.linkWb)} />}
      {!!data.linkWx && <img src={Wx} onClick={() => goHref(data.linkWx)} />}
      {!!data.linkDy && <img src={Dy} onClick={() => goHref(data.linkDy)} />}
      {!!data.linkKs && <img src={Ks} onClick={() => goHref(data.linkKs)} />}
    </div>
  );

}

function SourceView(props) {
  const slider1 = useRef(null);
  const slider2 = useRef(null);
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const { list } = props;

  useEffect(() => {
    setNav1(slider1.current);
    setNav2(slider2.current);
  }, [list]);

  const goto = (index) => {
    slider2.current.goTo(index);
  }

  return (
    <div className={styles.viewer}>
      {
        Array.isArray(list) && !!list.length && <Carousel
          dots={false}
          asNavFor={nav2}
          ref={slider1}
          effect="fade"
        >
          {list.map((item, key) => {
            return (
              <div className={styles.banner} key={`banner_${key}`}>
                <div className={styles.bannerInner}>
                  <div className={styles.left}>
                    <img className={styles.avatar} src={item.headUrl}/>
                    <h4>{item.name}</h4>
                    <div className={styles.desc}>{item.content}</div>
                    <Platform data={item} />
                  </div>
                  <div className={styles.right} style={{ backgroundImage: `url(${item.imgUrl})` }} onClick={() => goHref(item.link)}/>
                </div>
              </div>
            );
          })}
        </Carousel>
      }
      {
        Array.isArray(list) && !!list.length && <div className={styles.slider}>
          <Carousel
            dots={false} // 显示锚点
            asNavFor={nav1}
            ref={slider2}
            infinite={false} // 无限滚动
            focusOnSelect={true} // 选择时对焦
            slidesToShow={5} // 展示5个
            slidesToScroll={1} // 每次滚动1个
            arrows={true} // 允许箭头
            prevArrow={<SamplePrevArrow onClick={() => slider2.current.prev()}/>}
            nextArrow={<SampleNextArrow onClick={() => slider2.current.next()}/>}
          >
            {list.map((item, key) => {
              return (
                <div className={styles.item} style={{ width: 257 }} key={key} onClick={() => goto(key)}>
                  <span className={styles.img} style={{ backgroundImage: `url(${item.imgUrl})`}} />
                </div>
              );
            })}
          </Carousel>
        </div>
      }
    </div>
  );
}

export default SourceView;

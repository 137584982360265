import React, { useState, useEffect, useRef } from "react";
import styles from "./Index.module.scss";
import SourceView from './components/sourceView';
import Pingtai from '../../image/juzhen/pingtai_png.jpeg';
import axios from 'axios';

const jumpTo = (url) => {
  let a = document.createElement("a");
  a.setAttribute("href", url);
  a.setAttribute("target", "_blank");
  a.setAttribute("id", 'new_a');
  // 防止反复添加
  if(!document.getElementById('new_a')) {
      document.body.appendChild(a);
  }
  a.click();
  document.body.removeChild(a);
}

function Index(props) {
  const [sourceList, setSourceList] = useState([]);
  const [recommendList, setRecommendList] = useState([]);
  const [headList, setHeadList] = useState([]);
  useEffect(() => {
    axios.get('/admin/api/gw/queryContentList?type=2').then(res=>{
      const data = res.data;
      const list = data.data || [];
      setSourceList(list);
    })
    axios.get('/admin/api/gw/queryContentList?type=3').then(res=>{
      const data = res.data;
      const list = data.data || [];
      setRecommendList(list);
    })
    axios.get('/admin/api/gw/queryContentList?type=4').then(res=>{
      const data = res.data;
      const list = data.data || [];
      setHeadList(list);
    })
  }, []);

  return (
    <div className={styles.juzhen}>
      {/* 头部资源 */}
      <div className={styles.sourceViewRow}>
        <div className={styles.subTitle}>
          <h3>头部资源</h3>
          <p>具有超强影响力的博主</p>
        </div>
        <SourceView list={sourceList} />
      </div>
      {/* 本月重点推荐 */}
      <div className={styles.sourceViewRow}>
        <div className={styles.subTitle}>
          <h3>本月重点推荐</h3>
          <p>当月微博热议红人推荐</p>
        </div>
        <SourceView list={recommendList} />
      </div>
      <div className={styles.sourceViewRow}>
        <div className={styles.subTitle}>
          <h3>合作大V</h3>
        </div>
        <div className={styles.userList}>
          {!!headList.length && headList.map((item, index) => {
            return (
              <div className={styles.userItem} key={`userItem_${index}`}>
                <div className={styles.avatar} onClick={() => jumpTo(item.link)} style={{backgroundImage: `url(${item.headUrl})`}} />
                <div className={styles.name} onClick={() => jumpTo(item.link)}>{item.name}</div>
              </div>
            );
          })}
        </div>
      </div>
      {/* 头部资源 - 独家签约资源 */}
      <div className={styles.sourceViewRow} style={{ width: "100%", height: "600px", backgroundColor: "#e9f0f5" }}>
        <div className={styles.subTitle} style={{ paddingTop: "60px", paddingBottom: "70px" }}>
          <h3>触达平台</h3>
          <p>多样化推广渠道，多维度展示平台</p>
        </div>
        <div className={styles.platformList} style={{ backgroundImage: `url(${Pingtai})` }} />
      </div>
    </div>
  );
}

export default Index;
